.dropdown {
    position: absolute;
    top: 80px;
    z-index: 1000;
    background-color: white;
    border-radius: 8px;
    border-color: transparent;
    padding: 10px 0;
    transform: translate(-75%);
}

.dropdownItem {
    cursor: pointer;
    padding: 10px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    transition: filter 300ms;
    min-width: 200px;
}

.dropdownItem:hover {
    filter: brightness(0.9);
}

.dropdownItem:active {
    filter: brightness(0.8);
}

.dropdownItem p {
    color: rgb(11, 11, 77);
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
}