.loginPage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url(https://uploads-ssl.webflow.com/6044efa8ff1caf830c302fb3/6044efa8ff1caf176d30300f_Hero%20Wave%2002.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#loginBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

#loginBlock a {
  color: #4753cf;
  font-weight: 600;
  cursor: pointer;
}

#loginBlock a:hover {
  color: #2d379f;
}

#dontHaveAccountYet {
  font-size: 14px;
}


@media only screen and (max-width: 500px) { 
  .loginPage nav {
    margin-bottom: 0;
  }
}