.promptButton {
    padding: 18px 20px;
    border-radius: 8px;
    font-weight: 600;
    margin: 10px;
    font-size: 14px;
    font-family: 'Epilogue';
    text-align: center;
    cursor: pointer;
}

button:focus {
    outline: 0;
}

.fullButton {
    border: transparent;
    background-color: rgba(77, 90, 218, 1);
    color: white;
}

.fullButton:hover {
    background-color: rgba(57, 69, 184, 1);
}

.fullButton:active {
    background-color: rgba(45, 55, 159, 1);
}

.thirdPartyLoginButton {
    border-radius: 8px;
    border-color: rgb(228, 233, 242);
    border-style: solid;
    border-width: 1px;
    background-color: white;
    color: rgb(11, 11, 77);
}

.thirdPartyLoginButton:hover {
    background-color: whitesmoke
}

.thirdPartyLoginButton:active {
    background-color: lightgray;
}

.flatButton {
    border: transparent;
    background-color: transparent;
    color: rgba(77, 90, 218, 1);
}

.flatButton:hover {
    background-color: rgba(11, 11, 77, 0.05);
    color: rgba(57, 69, 184, 1);

}

.flatButton:active {
    background-color: rgba(11, 11, 77, 0.1);
    color: rgba(45, 55, 159, 1);
}

.lightButton {
    background-color:rgba(255, 255, 255, 0.12);
    border-color: rgba(255, 255, 255, 0.5);
    border-width: 1px;
    border-style: solid;
    color: white;
}

.lightButton:hover {
    background-color: rgba(11, 11, 77, 0.12);
}

.lightButton:active {
    background-color: rgba(11, 11, 77, 0.24);
}

.lightMeetingButton {
    background-color:#656ED8;
    border-color: rgba(255, 255, 255, 0.5);
    border-width: 1px;
    border-style: solid;
    color: white;
}

.lightMeetingButton:hover {
    background-color: #4750C1;
}

.lightMeetingButton:active {
    background-color: #4047B2;
}

.whiteButton {
    border: transparent;
    background-color: white;
    color: rgba(77, 90, 218, 1);
}

.whiteButton:hover {
    background-color:rgb(219, 221, 245);
    color: rgba(57, 69, 184, 1);

}

.whiteButton:active {
    background-color:rgb(200, 203, 241, 1);
    color: rgba(45, 55, 159, 1);
}

.iconButton {
    background-color: rgba(247, 249, 252, 1);
    border-radius: 50px;
    border-width: 1px;
    border-color: rgba(228, 233, 242);
    border-style: solid;
    margin: 15px;
    cursor: pointer;
    min-width: 4em;
    min-height: 4em;
}

.iconRowButton {
    background-color: rgba(247, 249, 252, 1);
    border-radius: 50px;
    border-width: 1px;
    border-color: rgba(228, 233, 242);
    border-style: solid;
    cursor: pointer;
    min-width: 4em;
    min-height: 3.5em;
}

.iconRowButton label {
    cursor: pointer;
}

.iconButton:hover {
    background-color: rgb(214, 218, 226);
}

.iconButton.active {
    background-color: rgb(203, 212, 228);
}

.icon {
    max-width: 1.2em;
    max-height: 1.2em;
    border-radius: 0px;
    object-fit: contain;
}

.icon-before {
    padding-right: 10px;
}

.icon-after {
    padding-left: 10px;
}

.contentHolder {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
}

.iconColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
}

.iconRow {
    display: flex;
    justify-content: center;
    justify-items: center;
}

.iconColumn img {
    margin: 10px 5px;
}

.iconRow img {
    margin: 10px;
}

.iconRow .icon {
    max-height: 1.4em;
    max-width: 1.4em;
    width: 1.4em;
    height: 1.4em;
}

.singleIcon * {
    margin: 10px 5px;
}