video {
    background-color: #EBEBEB;
}

.personDialog {
    display: flex;
    align-items: center;
    margin: 20px 0 20px 20px;
}

.personDialog img {
    max-width: 2em;
    max-height: 2em;
    border-radius: 50%;
    border-color: transparent;
    margin-right: 10px;
}

.personDialog p {
    color: rgb(11, 11, 77);
}

.top {
    object-position: 0 0;
}

#dialogImg {
    min-width: 100% !important;
    max-height: 25vh;
    border-radius: 0;
    border-color: transparent;
    object-fit: cover;
    margin-bottom: 20px;
}

#dialogVid {
    min-width: 100% !important;
    max-height: 25vh;
    border-radius: 0;
    border-color: transparent;
    object-fit: cover;
    margin-bottom: 20px;
}


@media only screen and (max-width: 955px) { 
    #dialogImg {
        max-height: 40vh;
    }

    #dialogVid {
        max-height: 40vh;
    }
}