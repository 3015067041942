.meetingFactDiv {
    display: flex;
    margin: 15px 0px;
    align-items: center;
}

.iconDiv {
    display: inline-block;
    border-radius: 0;
}

.iconDiv img {
    max-height: 15px;
    border-radius: 0;
}

.factDiv {
    display: inline-flex;
    flex-direction: column;
}

.factDiv p {
    margin: 3px 15px;
    color: #0B0B4D;
    font-size: 14px;
    display: inline-block
}

.fact{
    font-weight: 700;
}

#factTitle {
    font-size: 12px;
}