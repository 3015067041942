.updateContainer {
    flex-direction: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 30px;
    min-width: 40vw;
    max-width: 40vw;
}

.fetchedUpdateDiv .filePreviewButton {
    margin-bottom: 10px;
    padding-top: 0;
}

.editFetchedUpdateDiv .filePreviewButton {
    margin-bottom: 10px;
    padding-top: 0;
}

.fetchedUpdateDiv .filePreviewButton .icon {
    display: none;
}

.updateContainer .alert {
    margin-left: 10px;
}

.editAgendaContainerDiv button {
    padding: 0;
    margin-left: 0;
}

.editAgendaContainerDiv .flatButton .icon {
    max-width: 1em;
    max-height: 1em;
    object-fit: contain;
}

.updateContainer p {
    margin: 20px 10px 40px 10px;
}

.updateContainer h2 {
    margin: 30px 10px;
}

.updateContainer .flatButton {
    padding: 0;
    font-size: 14px;
    margin: 30px 10px;
}

.updateContainer .iconButton {
    min-height: 3.25em;
    min-width: 3.25em;
    margin: 0;
}

.updateContainer .flatButton .icon {
    width: 1em;
    height: 1em;
    object-fit: contain;
}

@media only screen and (min-width: 1100px) { 
    .updateContainer .textInput {
        width: 30vw;
    }
}

@media only screen and (max-width: 1100px) { 
    .updateContainer {
        margin: 0 15px;
        min-width: 70vw;
        max-width: 90vw;
    }
}

@media only screen and (max-width: 750px) { 
    .updateContainer {
        margin: 0;
    }
    .agendaPointDescription {
        min-width: 100%;
        width: 100%;
        max-width: 100%;
    }
}

@media only screen and (max-width: 500px) { 
    .updateContainer {
        min-width: 80vw;
        max-width: 80vw;
    }
}