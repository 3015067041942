.wrapper {
    position: relative;
    padding-top: 56vh;
}
.player {
  max-width: 70vw;
  position: absolute;
  top: 0%;
  left: 0%;
}

@media only screen and (max-width: 600px) and (min-height: 650px) { 
    .wrapper {
        padding-top: 40vh;
        margin-top: -100px;
    }
}


@media only screen and (max-width: 600px) and (max-height: 650px) { 
    .wrapper {
        padding-top: 50vh;
        margin-top: -100px;
    }
}

@media only screen and (max-width: 1400px) and (min-height: 1000px) { 
    .wrapper {
        padding-top: 35vh;
    }
}

@media only screen and (min-width: 1400px) and (min-height: 1000px) { 
    .wrapper {
        padding-top: 30vh;
    }
}
