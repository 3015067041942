.inputContainer {
    display: inline-block;
    margin-top: 5px;
}

.inputContainer .textInput {
    margin-bottom: 10px;
}

.agendaPointNo {
    display: inline;
    margin-right: 20px;
}

.agendaPoint {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    margin-bottom: 20px;
    max-width: 95vw;
}

.dropPoint {
    border-radius: 8px;
    border-color:rgb(11, 11, 77);
    border-style: solid;
    border-width: 2px;
    background-color: rgba(11, 11, 77, 0.2);
}

.dropPoint * {
    opacity: 0;
}

.agendaPoint:last-of-type {
    margin-bottom: 100px;
}

.current {
    opacity: 0;
}

.agendaList {
    margin-bottom: 60px;
}

.minutesDiv {
    display: inline-flex;
    flex-wrap: wrap;
    height: 2.4em;
    align-items: center;
}

.minutesDiv p {
    font-size: 14px;
    color: rgb(11, 11, 77);
}

.inputFieldMargin {
    margin: 10px;
}

#dragHandle {
    width: 100%;
}

.agendaPointOptions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(247, 249, 252, 1);
    border-radius: 50px;
    border-width: 1px;
    border-color: rgba(228, 233, 242);
    border-style: solid;
    margin: 15px;
    cursor: pointer;
    min-width: 2.5em;
}

.agendaPointOptions:hover {
    background-color: rgb(239, 242, 247);
}

.agendaPointOptions.active {
    background-color: rgb(203, 212, 228);
}

.iconHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    margin: 10px;
}

#addButtonForSmallScreen {
    display: none;
    margin-left: 10px;
    margin-top: 50px;
}

#addButtonForSmallScreen button {
    padding: 0px;
    
    margin-left: 0px;
}

#addButtonForBigScreen button {
    padding: 0px;
    margin-top: 30px;
}

@media only screen and (max-width: 450px) { 
    #addButtonForSmallScreen {
        display: inline-block;
    }
    #addButtonForBigScreen {
        display: none;
    }

    .agendaPoint:last-of-type {
        margin-bottom: 200px;
    }
}
