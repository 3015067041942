.meetingSummaryDiv {
  background-color: white;
  border-radius: 8px;
  padding: 20px 20px 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 28vw;
  max-height: 80vh;
  position: fixed;
  z-index: 100;
  right: 5%;
  filter: drop-shadow(0 0 10px rgba(71, 83, 207, 0.1));
  background-image: url(https://uploads-ssl.webflow.com/6044efa8ff1caf830c302fb3/6044efa8ff1caf8d5c303007_Hero%20Wave%2001.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.shareDiv {
  max-width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.shareDiv p {
  line-height: 1.5em;
}

.shareDiv h3 {
  margin-bottom: 0px;
}

.linkAndButtonDiv {
  display: flex;
  justify-items: space-between;
  align-items: center;
  width: 100%;
}

.buttonDiv {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.bottomMeetingSummary {
  display: none;
}

@media only screen and (max-width: 1100px) {
  .meetingSummaryDiv {
    display: none;
  }

  .bottomMeetingSummary .buttonDiv {
    justify-content: space-between;
    width: 95%;
    padding: 10px;
  }

  .bottomMeetingSummary {
    background-color: white;
    display: block;
    bottom: 0%;
    position: fixed;
    width: 100%;
    margin: 0;
    z-index: 100;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}
