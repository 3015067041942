.loginContainer {
    background-color: white;
    border-radius: 8px;
    border-color: transparent;
    border-style: solid;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    max-width: 30vw;
    min-height: 60vh;
    margin: auto;
    padding: 20px 40px;
    filter: drop-shadow(0 0 10px rgba(71, 83, 207, 0.1));
}

.loginContainer input {
    padding: 5px 10px;
    margin: 5px 0;
    font-size: 14px;
}

.loginContainerContent * {
    margin-left: 0;
    margin-right: 0;
}

.loginContainerContent button {
    margin: 10px 0;
}

.forgotPasswordDiv {
    display: flex;
    justify-content: center;
}

.errorMessage {
    color: darkRed;
    line-height: 1.5em;
    text-align: center;
}

.successMessage {
    color: darkgreen;
    line-height: 1.5em;
    text-align: center;
}

.errorMessageHolder {
    display: flex;
    max-width: 100%;
}

#forgotPassword {
    display: block;
    font-size: 14px;
    line-height: 1.5em;
    margin: 10px 0;
}

#or {
    margin: 10px 0;
    width: 100%;
    text-align: center;
    font-weight: 700;
}

.loginContainer .textInput {
    min-width: 95%;
    width: 95%;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 1100px) { 
    .loginContainer {
        max-width: 80vw;
    }
}

@media only screen and (max-width: 500px) { 
    .loginContainer .textInput {
        min-width: 70vw;
        width: 70vw;
    }
}
