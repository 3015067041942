.MuiInputLabel-root {
    margin-left: 15px;
    font-family: "Epilogue" !important;
    font-size: 13px !important;
}

.minutesUpdate {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.editUpdateButtonDiv .flatButton {
    margin-top: 15px;
    margin-bottom: 0;
}

.minutesUpdate .icon {
    display: inline;
    max-height: 14px;
    object-fit: contain;
    cursor: pointer;
    margin: 0 0 0 10px;
}

.minutesUpdate p {
    font-size: 16px;
    color: rgb(11, 11, 77);
    margin: 20px 10px;
    line-height: 2em;
    max-width: 33vw;
}

#linkPreviewHolder a {
    border-radius: 5px;
}

#linkPreviewHolder footer {
    display: none;
}

@media only screen and (max-width: 1100px) { 
    .minutesUpdate p {
        max-width: 60vw;
    }
}