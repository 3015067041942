.settingsTab {
    padding-left: 5vw;
    max-width: 65vw;
    height: 100%; 
    margin-bottom: 10vh;
}

.settingsTab .inputField {
    margin-left: 0;
}

.settingsTab h4 {
    margin-top: 40px;
    margin-bottom: 0;
}

@media only screen and (max-width: 800px) { 
    .settingsTab {
        max-width: 85vw;
        margin-bottom: 20vh;
        margin-top: 15vh;
    }
}
