#avatar {
    border-radius: 50%;
    border-color: transparent;
    width: 45px;
    height: 45px;
    margin: 5px;
    object-fit: cover;
    transition: transform 450ms;
}

#avatar:hover {
    transform: scale(1.1);
}

#avatarInitials {
    border-radius: 50%;
    border-color: transparent;
    width: 45px;
    height: 45px;
    margin: 5px;
    background-color: #8A94FF;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    text-align: center;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 600;
    line-height: 45px;
    color: rgba(255, 255, 255, 0.8);
    padding: 0;
    transition: transform 450ms;
}

#avatarInitials:hover {
    transform: scale(1.1);
}