#fixedToolBar {
    display: flex;
    position: fixed;
    bottom: 5%;
    align-items: center;
    justify-content: space-around;
    padding: 0 20px;
    width: 100%;
    z-index: 100;
}

#toolBarInputField {
    display: flex;
    align-items: center;
}

#toolBarInputField button {
    margin-left: 0;
}

#toolBarInputField .icon {
    max-height: 1.5em;
    max-width: 1.5em;
}

@media only screen and (max-width: 1100px) { 
    #fixedToolBar button .buttonText {
        display: none;
    }

    #fixedToolBar button .iconBefore {
        margin-right: 0;
    }

    #fixedToolBar button .iconAfter {
        margin-left: 0;
    }
}