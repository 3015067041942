input {
    height: 3em;
    border-radius: 8px;
    border-color: rgb(228, 233, 242);
    border-style: solid;
    border-width: 1px;
    font-size: 14px;
    font-family: 'Epilogue';
    font-weight: 500;
    color: rgba(160, 160, 160, 1);
    background-color: rgb(248, 250, 252);
    padding-left: 10px;
}

input:hover {
    background-color: white;
    border-color: rgb(143, 155, 179);
}

input:focus {
    background-color: white;
    border-color: rgb(71, 83, 207);
    color: rgb(11, 11, 77);
}

.textInput {
    width: 33vw;
}

.numberInput {
    width: 3em;
    display: inline-block;
}

.inputField {
    margin: 10px;
}

.titleInput {
    margin: 0;
}

.titleInput h1 {
    max-width: 35vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.agendaTitleDiv {
    display: flex;
    align-items: center;
}

.agendaTitleDiv .icon {
    margin-left: 10px;
    margin-bottom: 0;
    cursor: pointer;
}

.meetingInputField {
    display: flex;
    align-items: center;
    max-height: 3em;
}

.meetingTextInput {
    width: 50vw;
    height: 3em;
    font-size: 14px;
    color: rgb(11, 11, 77);
    border-radius: 8px 0 0 8px !important;
    padding-left: 20px;
}

.meetingTextInput:hover {
    border-color: transparent
}

.meetingTextInput:focus {
    border-color: transparent
}

.submitCommentButton {
    background-color: #8A94FF;
    border-radius: 0 8px 8px 0;
    border-color: transparent;
    border-style: solid;
    align-self: stretch;

    margin: 0;
    min-width: 3em;
}

.submitCommentButton:hover {
    background-color: rgba(45, 55, 159, 1);
}

.submitCommentButton:active {
    background-color: rgb(11, 11, 77);
}

#titleInputField {
    height: 2em;
    font-weight: 600;
    font-size: 34px;
    background-color: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 10px 0;
}

@media only screen and (max-width: 1100px) { 
    .textInput {
        width: 60vw;
    }

    .titleInput h1 {
        max-width: 60vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .meetingTextInput {
        width: 40vw;
    }
}

@media only screen and (max-width: 600px) { 
    #titleInputField {
        width: 75vw;
    }
    .titleInput h1 {
        max-width: 75vw;
    }

}

@media only screen and (max-width: 450px) { 
    #titleInputField {
        width: 75vw;
        max-width: 75vw;
    }
    .textInput {
        max-width: 55vw;
    }
}

@media only screen and (max-width: 400px) { 
    #titleInputField {
        width: 75vw;
        max-width: 75vw;
    }
    .textInput {
        max-width: 50vw;
    }
}

@media only screen and (max-width: 340px) { 
    #titleInputField {
        width: 75vw;
        max-width: 75vw;
    }
    .textInput {
        max-width: 40vw;
    }
}

