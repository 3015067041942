img {
    max-width: 18vw;
    border-radius: 8px;
}

.templateTile {
    display: inline-block;
    max-width: 18vw;
    margin: 10px;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 450ms;
}

.templateTile p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-height: 1.5em;        /* fallback */
    max-height: 3em;       /* fallback */
 }

.templateTile:first-of-type {
    margin-left: 10%;
}

.templateTile:last-of-type {
    margin-right: 10%;
}

.templateTile:hover {
    background-color: rgba(11, 11, 77, 0.05);
    transform: scale(1.04);
}

.templateTile:hover p{
    color: rgb(122, 122, 122);
}

.templateTile h3 {
    max-width: 18vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.templateTile:active p{
    color: rgb(87, 87, 87);
}

.templateTile:active {
    background-color: rgba(11, 11, 77, 0.1);
}

.templateTile img {
    filter: drop-shadow(0 0 5px rgba(71, 83, 207, 0.2));
}

#templateDescription {
    font-size: 14px;
}

@media only screen and (max-width: 1200px) { 
    .templateTile {
        min-width: 20%;
        max-width: 25%;
    }

    .templateTile img {
        min-width: 90%;
        max-width: 100%;
    }

    .templateTile h3 {
        max-width: 90%;
    }
}

@media only screen and (max-width: 1100px) { 
    .templateTile {
        min-width: 30%;
        max-width: 32.5%;
    }

    .templateTile img {
        min-width: 90%;
        max-width: 100%;
    }

    .templateTile h3 {
        max-width: 90%;
    }
}

@media only screen and (max-width: 900px) { 
    .templateTile {
        min-width: 35%;
        max-width: 40%;
    }

    .templateTile img {
        min-width: 90%;
        max-width: 100%;
    }

    .templateTile h3 {
        max-width: 90%;
    }
}

@media only screen and (max-width: 600px) { 
    .templateTile {
        min-width: 40%;
        max-width: 45%;
    }

    .templateTile img {
        min-width: 90%;
        max-width: 100%;
    }

    .templateTile h3 {
        max-width: 90%;
    }
}

@media only screen and (max-width: 550px) { 
    .templateTile {
        min-width: 50%;
        max-width: 60%;
    }
}

@media only screen and (max-width: 500px) { 
    .templateTile:first-of-type {
        margin-left: 5%;
    }
}

@media only screen and (max-width: 350px) { 
    .templateTile {
        min-width: 70%;
        max-width: 90%;
    }
}