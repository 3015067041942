.settingsInputSection {
    margin: 30px 0;
}

.settingsInputSection p {
    font-size: 15px;
}

#settingsDangerZone .fullButton {
    background-color: rgb(199, 13, 13);
    margin-left: 0;
}

#settingsDangerZone .fullButton:hover {
    background-color: rgb(173, 12, 12);
}

#settingsDangerZone .fullButton:active {
    background-color: rgb(145, 11, 11);
}

@media only screen and (max-width: 500px) { 
    .settingsInputSection .textInput {
        min-width: 70vw;
        width: 70vw;
        max-width: 70vw;
    }
  }