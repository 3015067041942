.agendaPreview {
    max-width: 100vw;
    overflow-x: hidden;
}

.agendaPreviewDiv {
    width: 80vw;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20vh;
}

.agendaPreviewTitle {
    max-width: 35vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.agendaPreviewDiv .alert {
    margin-top: 20px;
}

.topicNoHeader {
    margin: 0;
}

.agendaPreviewDiv p {
    max-width: 33vw;
    line-height: 1.75em;
}

.agendaUpdateContainerWrapper {
    display: flex;
    align-items: flex-start;
    margin: 60px 0;
}

@media only screen and (max-width: 1100px) { 
    .agendaPreviewDiv p {
        max-width: 60vw;
    }

    .agendaPreviewTitle {
        max-width: 60vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media only screen and (max-width: 750px) { 
    .topicNoHeader {
        display: none;
    }

    .agendaPreviewTitle {
        max-width: 80vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media only screen and (max-width: 500px) { 
    .agendaPreviewDiv {
        width: 85vw;
    }
}