.agendaDiv {
    width: 80vw;
    margin: 10px auto;
    display: flex;
    justify-content: space-between;
}

.agendaCalendar {
    border-color: transparent !important;
    border-radius: 8px;
    border-style: solid;
    padding: 5px;
    font-family: 'Epilogue';
}

.agendaCalendar button {
    border-radius: 8px;
}

.loadingAgendaDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 40vw;
}

#instructionTitle {
    margin: 0;
}

#meetingTitle {
    margin-top: 15px;
    margin-bottom: 0;
}

#teammatesPar {
    margin-top: 0;
}

#editableAgendaDateSection {
    display: flex;
    flex-wrap: wrap;
    flex-direction: flex-start;
    align-items: center;
}

#editableAgendaDateSection p {
    margin-top: 10px;
    margin-bottom: 20px;
    color: rgb(11, 11, 77);
}

#editableAgendaDateSection .icon {
    margin: 10px 10px 20px 0;
    padding-bottom: 5px;
}

#editableAgendaDateSection button {
    padding: 0;
    margin-top: 10px;
    margin-bottom: 20px;
}

#meetingMetaDataSection .inputField {
    margin-left: 0;
}

@media only screen and (max-width: 1200px) { 
    .agendaDiv {
        margin-bottom: 40px;
        width: 85vw;
    }

    .loadingAgendaDiv {
        max-width: 60vw;
    }
}

#agendaCreator {
    max-width: 100vw;
    overflow: hidden;
}

@media only screen and (max-width: 600px) { 
    .dontShowOnMobile {
        display: none;
    }
    
    #meetingMetaDataSection .textInput {
        width: 80vw;
        max-width: 80vw;
    }
}