.floatingBottomRight {
  position: fixed;
  z-index: 100;
  right: 2%;
  bottom: 2.5%;
}


@media only screen and (min-width: 1100px) { 
    .smallScreenOnly {
        display: none;
    }
    .smallScreen {
      display: none;
    }
}

@media only screen and (max-width: 1100px) { 
  .bigScreen {
    display: none;
  }
}
