.filePreviewButton {
  display: flex;
  align-items: center;
}

.filePreviewButton button {
  max-width: 200px;
}

.filePreviewButton img {
  max-height: 3em;
  margin-right: 10px;
  margin-left: 10px;
}

.filePreviewButton .icon {
  max-height: 14px;
  cursor: pointer;
}

.filePreviewButton .buttonText {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

@media only screen and (max-width: 500px) {
  .filePreviewButton .buttonText {
    font-size: 12px;
  }
  .filePreviewButton button {
    max-width: 150px;
  }
  .filePreviewButton img {
    display: none;
  }
}
