
.shareLink {
    display: inline;
    padding: 20px;
    background-color: #E8E9FE;
    border-color: transparent;
    border-radius: 8px;
    max-width: 70%;
    max-height: 2.5em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #4753CF;
    font-size: 14px;
}