.promptGridTile {
    border-radius: 8px;
    border-color: #E4E9F2;
    border-style: solid;
    border-width: 1px;
    padding: 20px;
    display: inline-flex;
    justify-content: flex-start;
    transition: transform 450ms;
}

.promptGridTile:hover {
    transform: scale(1.04);
}

.promptGridTile p {
    color: #E5E5E5;
    width: 70%;
    line-height: 1.5em;
    margin-left: 20px;
    margin-top: 5px;
}

.noUpdatesTile {
    border-radius: 8px;
    border-color: #E4E9F2;
    border-style: solid;
    border-width: 1px;
    padding: 20px;
    display: flex;
    justify-content: center;
    transition: transform 450ms;
    margin-bottom: 25vh;
}

.noUpdatesTile:hover {
    transform: scale(1.04);
}

.noUpdatesTile p {
    color: #E5E5E5;
    width: 70%;
    line-height: 1.5em;
    margin-left: 20px;
    margin: 5px;
    text-align: center;
}

@media only screen and (max-width: 1100px) { 
    .promptGridTile {
        width: 90%;
        grid-column: 1 / span 2;
    }

    .noUpdatesTile {
        width: 70%;
    }
}