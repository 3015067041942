.tag {
    padding: 5px 10px;
    border-radius: 8px;
    border-color: transparent;
    border-style: solid;
    background-color: #14CC9E;
    color: white;
    font-weight: 600;
    font-size: 12px;
    display: inline-flex;
    max-height: 1em;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;
    margin-left: 5%;
}

.tag p {
    margin: 0;
    text-align: center;
    padding: 0;
}