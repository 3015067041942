.noMeetingsTile {
    display: flex;
    max-width: 100%;
    margin: 40px 10px 10vh 10px;
    padding: 40px;
    justify-content: space-between;
    justify-items: space-between;
    align-content: center;
    align-items: center;
    border-radius: 8px;
    border-color: transparent;
    background-color: rgba(138, 148, 255, 1);
    transition: transform 450ms;
    grid-column: 2 / 6;
    filter: drop-shadow(0 0 5px rgba(71, 83, 207, 0.2));
}

.noMeetingsTile:hover {
    background-color: rgb(122, 131, 236);
    transform: scale(1.04);
}

#noMeetingsParagraph {
    color: white;
    font-weight: 600;
    margin-left: 10px;
}

#paperPlane {
    max-width: 25%;
}

#leftDiv {
    max-width: 25%;
}

@media only screen and (max-width: 1200px) { 
    #paperPlane {
        max-width: 30%
    }

    #leftDiv {
        max-width: 50%;
    }
}

@media only screen and (max-width: 900px) { 
    #paperPlane {
        display: none;
    }
    #leftDiv {
        min-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;
        text-align: center;
    }
}

@media only screen and (max-width: 600px) { 
    .noMeetingsTile {
        margin: 40px 10px 20vh 10px;
    }
}