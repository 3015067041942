.day {
    color: white;
    font-size: 50px;
    margin: 5px;
}

.month {
    color: white;
    font-size: 20px;
    font-weight: 400;
    margin: 5px;
}

.date {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-items: flex-start;
    margin: 5px;
}

.meetingTitle {
    color: white;
    font-size: 18px;
    font-weight: 600;
    margin: 5px;
}


.meetingTile {
    display: inline-flex;
    flex-direction: column;
    background-color: rgba(138, 148, 255, 1);
    align-items: center;
    justify-items: space-between;
    justify-content: space-between;
    min-width: 16%;
    max-width: 16%;
    min-height: 35vh;
    margin: 10px;
    padding: 15px 10px;
    border-radius: 8px;
    transition: transform 450ms;
    filter: drop-shadow(0 0 5px rgba(71, 83, 207, 0.2));
}

.meetingTile h4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-height: 1.25em;        /* fallback */
    max-height: 2.5em;       /* fallback */
 }

.meetingTile p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-height: 1.25em;        /* fallback */
    max-height: 2.5em;       /* fallback */
 }

.meetingTile:first-of-type {
    margin-left: 10%;
}

.meetingTile:last-of-type {
    margin-right: 10%;
}

.meetingTile:hover {
    background-color: rgb(122, 131, 236);
    transform: scale(1.04);
}

#buttonHolder {
    display: flex;
    justify-content: center;
}

#meetingDescription {
    color: rgb(234, 234, 234);
    font-size: 14px;
}

#titleDescriptionContainer {
    width: 90%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: flex-start;
    margin: 10px;
}

@media only screen and (max-width: 1200px) { 
    .meetingTile {
        min-width: 20%;
        max-width: 20%;
    }
}

@media only screen and (max-width: 1100px) { 
    .meetingTile {
        min-width: 27.5%;
        max-width: 27.5%;
    }
}

@media only screen and (max-width: 900px) { 
    .meetingTile {
        min-width: 35%;
        max-width: 35%;
    }
}

@media only screen and (max-width: 600px) { 
    .meetingTile {
        min-width: 50%;
        max-width: 60%;
    }
}

@media only screen and (max-width: 550px) { 
    .meetingTile {
        min-width: 60%;
        max-width: 70%;
    }
}

@media only screen and (max-width: 500px) { 
    .meetingTile:first-of-type {
        margin-left: 7%;
    }
}

@media only screen and (max-width: 350px) { 
    .meetingTile {
        min-width: 80%;
        max-width: 90%;
    }
}

@media only screen and (min-height: 1100px) { 
    .meetingTile {
        min-height: 25vh;
    }
}

@media only screen and (min-height: 1500px) { 
    .meetingTile {
        min-height: 10vh;
    }
}