nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 10px 20px;
    border-bottom: 1px solid white;
    margin-bottom: 60px;
    min-height: 9vh;
}

.logo {
    max-height: 60px;
    margin-left: 10px;
    cursor: pointer;
}

.flexboxAlignCenter {
    min-width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flexboxAlignCenter #avatar {
    margin: 0;
}

.flexboxAlignCenter button {
    margin-left: 40px;
}

#hideWhenLarge {
    display: none
}

@media only screen and (max-width: 1100px) { 
    .hideWhenSmall {
        display: none;
    }

    #hideWhenLarge {
        display: inline-block;
    }
}
