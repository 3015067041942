#promptGrid {
    display: grid;
    margin: 40px 10vw;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 5vh;
    margin-bottom: 25vh;
    grid-auto-rows: minmax(min-content, max-content);
    grid-auto-flow: row dense;
}

#doneButtonDiv {
    display: inline-flex;
    justify-content: flex-end;
    grid-column: 1/ span 2;
}

#doneButtonDiv button {
    margin: 0;
}

@media only screen and (max-width: 1100px) { 
    #doneButtonDiv {
        display: flex;
        justify-content: flex-end;
        width: 95%;
    }

    #promptGrid {
        justify-content: center;
        justify-items: center;
    }
}

/* #promptGrid {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10vh;
    margin-bottom: 25vh;
    align-items: flex-start;
    align-content: flex-start;
} */