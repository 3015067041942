.meetingPromptWrapper {
    background-color: rgba(77, 90, 218, 1);
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    overflow-y: scroll;
}

#meetingDetailsDiv {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: center;
}

#meetingDetailsDiv h1 {
    color: white;
    font-size: 4em;
    margin-bottom: 20px;
}

#meetingDetailsDiv p {
    color: #EAEAEA;
    margin-bottom: 35px;
}

#topicDetailsDiv {
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

#topicDetailsDiv h1 {
    color: white;
}

#topicDetailsDiv p {
    color: #EAEAEA;
}