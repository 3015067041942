footer {
    max-width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #182135;
    padding: 20px 10%;
    min-height: 10vh;
    margin-top: 10vh;
}

footer img {
    max-height: 30px;
    object-fit: contain;
    border-radius: 0;
    border-color: transparent;
}

footer p {
    color: #E5E5E5;
    opacity: 0.8;
    display: inline;
    font-size: 14px;
    margin: 0 10px;
}

footer a:hover {
    color: rgba(138, 148, 255, 1);
    cursor: pointer;
}

@media only screen and (max-width: 900px) { 
    footer {
        flex-direction: column;
        justify-content: space-around;
    }

    footer img {
        display: none;
    }
}

@media only screen and (max-width: 500px) { 
    footer {
        display: none;
    }

    footer img {
        display: none;
    }
}
