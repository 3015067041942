.alert {
    max-width: 34vw;
    margin: 30px 0px 20px 0px;
}

.alert * {
    font-family: 'Epilogue';
}

@media only screen and (max-width: 1100px) { 
    .alert {
        max-width: 61vw;
    }
}

@media only screen and (max-width: 900) { 
    .alert {
        max-width: 80vw;
    }
}