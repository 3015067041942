.templateSkeleton * {
  display: inline-block;
}

.MuiSkeleton-root {
    width: 18vw;
    margin: 20px 40px 20px 0;
}

@media only screen and (max-width: 1200px) { 
    .MuiSkeleton-root {
        width: 20vw;
    }
}

@media only screen and (max-width: 900px) { 
    .MuiSkeleton-root {
        width: 35vw;
    }
}

@media only screen and (max-width: 600px) { 
    .MuiSkeleton-root {
        width: 50vw;
    }
}

@media only screen and (max-width: 550px) { 
    .MuiSkeleton-root {
        width: 60vw;
    }
}

@media only screen and (max-width: 350px) { 
    .MuiSkeleton-root {
        width: 80vw;
    }
}