#settings nav {
    margin-bottom: 0;
    background-color: white;
    width: 97.5%;
    position: fixed;
}

#smallScreenSettings {
    display: none;
}

@media only screen and (max-width: 800px) { 
    #smallScreenSettings {
        display: block;
    }

    #largeScreenSettings {
        display: none;
    }
}