.dashboard footer {
    margin-top: 0;
}

.dashboardBody {
  display: grid;
  grid-template-columns: 10% 2fr 2fr 2fr 2fr 10%;
  margin: 0 auto;
  background-image: url(https://uploads-ssl.webflow.com/6044efa8ff1caf830c302fb3/6044efa8ff1caf8d5c303007_Hero%20Wave%2001.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.sectionTitleDiv {
  grid-column: 2 / 6;
  margin-left: 10px;
}

.sectionTitleDiv h2 {
  margin-bottom: 0px;
}

.noMeetingsTileGridDiv {
  grid-column: 2 / 6;
}

.templateTiles {
  grid-column: 2 / 6;
}

.slider {
  grid-column: 1 / 7;
  padding: 10px;
  margin-bottom: 25px;
}

.templateTileSkeletonDiv {
  grid-column: 2 / 7;
  padding: 10px;
  margin-bottom: 25px;
}

@media only screen and (min-width: 1000px) and (max-width: 1300px) { 
  .sectionTitleDiv {
    margin-right: 10px;
  }
}


@media only screen and (max-width: 500px) { 
  .sectionTitleDiv p {
    font-size: 14px;
  }

  .dashboardBody {
    grid-template-columns: 5% 2fr 2fr 2fr 2fr 5%;
  }

  .slider {
    padding-left: 0px;
  }
}
